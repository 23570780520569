body {
            background: #e0d6f1;
            overflow:hidden;
            text-rendering: optimizeLegibility;
             -webkit-font-smoothing: antialiased;
             /*font-family: 'Spectral', serif;*/
             width: 100%;
        }

        a.link_web{
            color:black;
            text-decoration: none;
        }
        a.link_vid:hover{
            color:black;
        }
        a.link_vid{
            color:white;
            background: black;
            padding:1px 4px 1px 4px;
            border-radius: 5px;
            text-decoration: none;
        }
        a.link_vid:hover{
            color:black;
            background: white;
            border: 1px solid black;
        }

        .big_div2{
            position: absolute;
            top:0px;

            z-index: 100;
            width: 100%;
            height: 100%;
        }

        #photo_enlarge{
            display: none;
            width: 100%;
            height:100%;
            position: fixed;
            top:0px;
            left:0px;
            z-index: 20000;
        }
        #photos_main{
            margin: 0;
            width: 60%;
              position: fixed;
              top: 50%;
              left: 36%;
              transform: translate(-36%, -50%) rotate(-24deg);
              padding: 1%;
              z-index: -1;
        }
        #photos_main2{
            margin: 0;
            width: 60%;
              position: fixed;
              top: 50%;
              left: 36%;
              transform: translate(-36%, -50%) rotate(-24deg);
              padding: 1%;
        }
        .enlarged_photo{
            width: 100%;
        }

        #main_bg{
            width: 100%;
        }
        #main_layer{
            width: 100%;
        }
        #canvas{
            z-index: 50000;
        }
        #project_content2{
            padding: 19px;
            font-size: 12px;
            font-weight: 500;
            width: 20%;
            position: absolute;
            top:6%;
            right:9%;
            background: #e0d6f1;
            border: 1px solid black;
            height: fit-content;
            display: none;
        }
        #desc{
            width: 100%;
            height:100%;
            position: fixed;
            top: 0px;
            bottom: 0px;
            display: none;
            z-index: 60000;
        }
        .project_title2{
            font-size: 32px;
            font-weight: 500;
        }
        .small_menu{
            padding-left: 14px;
            display: none;
        }
        .submenu{
            cursor : pointer;
        }
        
        .back{
            width:20px;
            position: fixed;
            top:0px;
            left: 0px;
            height:100%;
            line-break:anywhere;
            word-break: break-all;
            font-size:9px;
            z-index: 4000;
        }
        .back_link{
            color:black;
            text-decoration-line: none;
        }
        #shade_div{
            background: #e0d6f1;
            opacity: 1;
            position: fixed;
            top:0px;
            left:0px;
            width: 100%;
            height:100%;
            z-index: 100000;
        }
        .shade_animation{
            animation-name: shade;
            animation-duration: 1s;
            animation-fill-mode: forwards;
            animation-delay: 1s;
        }
        @keyframes shade {
          from {opacity: 1;z-index: 100000;display: inherit;}
          to {opacity: 0; z-index: -100;display: none;}
        }
        #table {

            width: 100%;
            border-collapse: collapse;
            border-top: 1px solid black;
            /*border-bottom: 1px solid black;*/
        }
        #table_div{
            width: 100%;
            /*display:none;*/
            position:absolute;
            bottom:0;
            left:0;
            font-size: 10px;
            z-index: 30000;

        }
        th, td {
          padding: 1px;
          padding-left: 7px;
          text-align: left;
            border-bottom: 1px solid black; 
        
        }
        tr:hover{
            background-color: black;
            color:white;
        }
        #project_img_container1{
            display: none;
            padding: 19px;
            font-size: 12px;
            font-weight: 500;
            width: 16%;
            position: absolute;
            top:6%;
            left:9%;
            background: #e0d6f1;
            border: 1px solid black;
        }
        #project_img_container2{
            display: none;
            padding: 19px;
            font-size: 12px;
            font-weight: 500;
            width: 24%;
            position: absolute;
            bottom:26%;
            left:4%;
            background: #e0d6f1;
            border: 1px solid black;
            height: fit-content;
        }
        #project_img_container3{
            display: none;
            padding: 19px;
            font-size: 12px;
            font-weight: 500;
            width: 30%;
            position: absolute;
            bottom:6%;
            right:4%;
            background: #e0d6f1;
            border: 1px solid black;
        }
        .project_img{
            width: 100%;
        }
        @media screen and (max-width: 600px) {

            #table_div{
                display: inherit;
            }
            #photos_main{
            margin: 0;
              position: fixed;
              top: 0%;
              left: 0%;
              transform: inherit;
              z-index: -1;
              padding: 0%;
              width: 100%;
        }
        #photos_main2{
            margin: 0;
              position: fixed;
              top: 0%;
              left: 0%;
              transform: inherit;
              z-index: 1000;
              padding: 0%;
              width: 100%;
        }

            .gal_prev{

              left: 0%;
              transform: rotate(-90deg) translate(50%, 5%);
        }
        .gal_next{

              right: 0%;
              transform: rotate(90deg) translate(-50%, 5%);
        }

            .enlarged_photo_div{

            width: 80%;
        }
            body {
            overflow-y:scroll;
            overflow-x:hidden;
           
        }
          .photos_popup{
                width:100%;
                margin-top: -40%;
            }

        #project_content2{
            position: fixed;
            bottom: 20%;
            width: 75%;
            padding: 24px;
            font-size: 12px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 60000;
        }
        #project_img_container1{
            position: fixed;
            bottom: 10%;
            width: 50%;
            padding: 24px;
            font-size: 12px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 60000;
        }
        #project_img_container2{
            position: fixed;
            bottom: 10%;
            width: 72%;
            padding: 24px;
            font-size: 12px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 60000;
        }
        #project_img_container3{
            position: fixed;
            bottom: 10%;
            width: 70%;
            padding: 24px;
            font-size: 12px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 60000;
        }
        .big_div2{
            position: relative;
            /*height: 100%;*/
            width: 100%;
            left: 0px;
        }
        #photo_stack{
            margin-top: auto;
            overflow: hidden;
        }
        
        }