.App {

}
.port_imgs{
  width: 70%;
}

.port_imgs_tactics{
  width: 30%;
}

  .main_p{
  font-size:28px;
  width:84%;
  padding: 8%;
  line-height:35px;
  margin-left: 0px;
}
.not_main_p{
  font-size:28px;
  width:84%;
  padding: 8%;
  padding-top: 1%;
  padding-bottom: 1%;
  line-height:35px;

}
.desc_title{
    font-size:52px;
  width:84%;
  padding: 1%;
  padding-top: 1%;
  padding-bottom: 1%;
  line-height:35px;
  /*font-family: 'Srisakdi', cursive;*/
  /*font-weight: 700;*/
}

.desc_p{
    font-size:26px;
  width:44%;
  padding: 1%;
  padding-top: 1%;
  padding-bottom: 1%;
  line-height:35px;
}
.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/*body{
  background: #e0d6f1;
  font-family: 'Spectral', serif;
  font-size:28px;
  /*overscroll-behavior:none;*/
  /*position: fixed;*/
  /*overflow: hidden;*/
/*}*/

html,
body {
    margin: 0;
    overflow: hidden;
    height: 100%;
    font-family: serif;
}

/* Scale canvas with resize attribute to full size */
canvas[resize] {
    width: 100%;
    height: 100%;
}

a.inside{
  text-decoration: none;
  color:black;letter-spacing: 12px; 
  word-break: break-word;
}

a.outside{
  color:black;
  word-break: break-word;
}
.container{
  /*background:#ffabd1;*/
  position: relative;
  font-size: 24px
}
.button_dot{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70px;
  height: 70px;
  background: #d61d2a;
  border-radius: 35px;
  border:1px solid black;
}
.sexGamePopup_container{
  background:white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 34%;
  border: 1px solid rgba(0,0,0,1);
  padding:21px;
  transition: opacity 1.2s ease-in-out;
}

.sexGamePopup_container2{
  background:white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 34%;
  border: 1px solid rgba(0,0,0,1);
  padding:21px;
  opacity: 0;
  transition: opacity .7s ease-in-out;
}
@keyframes fadeInFromNone {
    0% {
        display: none;
        opacity: 0;
    }

    1% {
        display: block;
        opacity: 0;
    }

    100% {
        display: block;
        opacity: 1;
    }
}

.sexGamePopup{
  margin-bottom: 24px;
}
.sexGamePopup2{
  text-align: center;
  margin-bottom: 24px;
}

.sexGamePopup_loading{
  margin-bottom: 24px;
  width: 120px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.sexGamePopup_yes{
  display: inline-block;
  border-top:1px dotted #666666;
  width: 42%;
  margin-right:5%;
}

.sexGamePopup_yes:hover{
  
  /*color:#e0d6f1 !important;*/
  /*background: rgba(0,0,0,0.1);*/
  border-top:1px solid black;
  /*padding-top:5px;*/
}
.container{
  background:#f2f2f2;
  padding-top:200px;

}
.span_white{
  background: white;
/*  width: 84%;
  height: 100%;*/
}
.inner_scene{
  position: fixed;
  width: 60%;
  /*height:440px;*/
  top:0;
  left:0;
  /*margin: 80px auto;*/
  /*margin-top:200px;*/
  perspective: 1000px;
  margin-left:-10%;
  transform: rotateY( 0deg) translateZ(-292px) translateX(-500px);
  transform-style: preserve-3d;
  transition-timing-function: ease-in-out;
  transition-delay: 0s;
  /*transition: transform 8s;*/
}
.inner_scene1{
  position: fixed;
  width: 50%;
  /*height:440px;*/
  top:50%;
  left:50%;
  /*margin: 80px auto;*/
  /*margin-top:200px;*/
  perspective: 1000px;
  /*margin-left:7%;*/
  transform: rotateY( 0deg) translateZ(-292px) translateX(-50%) translateY(-50%);
  transform-style: preserve-3d;
  transition-timing-function: ease-in-out;
  transition-delay: 0s;
  /*transition: transform 8s;*/
}
.inner_scene2{
  position: fixed;
  width: 100%;
  height:440px;
  top:0;
  left:0;
  /*margin: 80px auto;*/
  /*margin-top:200px;*/
  perspective: 1000px;
  /*margin-left: 45%;*/
  /*margin-top: -25%;*/
  transform: rotateY( 0deg) rotateZ( 0deg);
  /*transform: translateZ(-200px);*/
  transform-style: preserve-3d;
}
.scene {
  border: 1px solid #CCC;
  /*margin: 40px 0;*/
  position: relative;
  width: 100%;
  height:440px;
  /*margin: 80px auto;*/
  /*margin-top:200px;*/
  perspective: 1000px;
  transform: rotateY( 40deg) rotateZ( 10deg);
  transform-style: preserve-3d;

  /*opacity: 0.3; */
  /*opacity: 1; 
  transition: opacity 2.4s;*/
}
.forAnimation{
  opacity:0;
  animation-name: example;
  animation-duration: 4s;
  animation-delay: 3s;
  animation-fill-mode: forwards;
}
@keyframes example {
  from {opacity: 0;}
  to {opacity: 1;}
}
@keyframes carousel_ani {
  from {transform: translateZ(-381px) rotateX(0deg);}
  to {transform: translateZ(-381px) rotateX(720deg);}
}
@keyframes carousel_ani2 {
  from {transform: translateZ(-1207px) rotateX(0deg);}
  to {transform: translateZ(-1207px) rotateX(720deg);}
}
@keyframes carousel_cell_ani2 {
  from {opacity: 0.8}
  to {opacity: 0.2}
}

.carousel {
  width: 100%;
  height: 100%;
  position: absolute;
  transform: translateZ(-192px);
  transform-style: preserve-3d;
  transition: transform 1s;

  animation-name: carousel_ani;
  animation-duration: 4.5s;
  animation-delay: 2s;
  animation-timing-function: ease-out;
  /*animation-fill-mode: forwards;*/

}

.carousel__cell {

  position: absolute;
  border: 1px solid black;
  /*background: white;*/
  width: 100%;
  height: 100%;
  left: 10px;
  top: 10px;
  line-height: 43px;
  font-size: 34px;
  /*font-family: 'Overlock', cursive;*/
  font-family: 'Padauk',sans-serif;

  font-weight: bold;
  color: black;
  transition: transform 1s, opacity 1s;
  transform-style: preserve-3d;
  z-index: 1000;
  opacity: 0.2;
    -webkit-transform: translate3d(1, 0, 0);
-webkit-transform-style: preserve-3d;
-webkit-perspective: 1000;
}

/*.carousel__cell:nth-child(9n+1) { background: hsla(  0, 100%, 50%, 1); }
.carousel__cell:nth-child(9n+2) { background: hsla( 40, 100%, 50%, 1); }
.carousel__cell:nth-child(9n+3) { background: hsla( 80, 100%, 50%, 1); }
.carousel__cell:nth-child(9n+4) { background: hsla(120, 100%, 50%, 0.8); }
.carousel__cell:nth-child(9n+5) { background: hsla(160, 100%, 50%, 0.8); }
.carousel__cell:nth-child(9n+6) { background: hsla(200, 100%, 50%, 0.8); }
.carousel__cell:nth-child(9n+7) { background: hsla(240, 100%, 50%, 0.8); }
.carousel__cell:nth-child(9n+8) { background: hsla(280, 100%, 50%, 0.8); }
.carousel__cell:nth-child(9n+0) { background: hsla(320, 100%, 50%, 0.8); }*/

.carousel__cell:nth-child(1) { transform: rotateX(  0deg) translateZ(192px); }
.carousel__cell:nth-child(2) { transform: rotateX( 40deg) translateZ(192px); }
.carousel__cell:nth-child(3) { transform: rotateX( 80deg) translateZ(192px); }
.carousel__cell:nth-child(4) { transform: rotateX(120deg) translateZ(192px); }
.carousel__cell:nth-child(5) { transform: rotateX(160deg) translateZ(192px); }
.carousel__cell:nth-child(6) { transform: rotateX(200deg) translateZ(192px); }
.carousel__cell:nth-child(7) { transform: rotateX(240deg) translateZ(192px); }
.carousel__cell:nth-child(8) { transform: rotateX(280deg) translateZ(192px); }
.carousel__cell:nth-child(9) { transform: rotateX(320deg) translateZ(192px); }

.carousel__cell_in{
  
  width: 92%;
  height:92%;
  margin:2% auto;
    /*overflow-y: scroll;*/
    /*overscroll-behavior:contain;*/
  /*-webkit-overflow-scrolling: touch;*/
}

.scene2 {
  
  /*margin: 40px 0;*/
  position: relative;
  width: 100%;
  height:1000px;
  /*margin: 80px auto;*/
  /*margin-top:200px;*/
  perspective: 1000px;
  transform: rotateY( 40deg) rotateZ( -10deg);
  transform-style: preserve-3d;
}

.carousel2 {
  width: 100%;
  height: 100%;
  position: absolute;
  transform: translateZ(-192px);
  transform-style: preserve-3d;
  transition: transform 1s;

  animation-name: carousel_ani2;
  animation-duration: 6.5s;
  animation-delay: 0s;
  animation-timing-function: ease-out;
}

.carousel__cell2 {

  position: absolute;
  /*border: 1px solid black;*/
  background: transparent;
  width: 100%;
  height: 100%;
  left: 10px;
  top: 10px;
  line-height: 41px;
  font-size: 34px;
  font-weight: bold;
  color: black;
  transition: transform 1s, opacity 1s;
  transform-style: preserve-3d;
  z-index: 1000;
  opacity: 0.2;
/*
  animation-name: carousel_cell_ani2;
  animation-duration: 4.5s;
  animation-delay: 0s;*/
  /*animation-timing-function: ease-out;*/
}

/*.carousel__cell:nth-child(9n+1) { background: hsla(  0, 100%, 50%, 1); }
.carousel__cell:nth-child(9n+2) { background: hsla( 40, 100%, 50%, 1); }
.carousel__cell:nth-child(9n+3) { background: hsla( 80, 100%, 50%, 1); }
.carousel__cell:nth-child(9n+4) { background: hsla(120, 100%, 50%, 0.8); }
.carousel__cell:nth-child(9n+5) { background: hsla(160, 100%, 50%, 0.8); }
.carousel__cell:nth-child(9n+6) { background: hsla(200, 100%, 50%, 0.8); }
.carousel__cell:nth-child(9n+7) { background: hsla(240, 100%, 50%, 0.8); }
.carousel__cell:nth-child(9n+8) { background: hsla(280, 100%, 50%, 0.8); }
.carousel__cell:nth-child(9n+0) { background: hsla(320, 100%, 50%, 0.8); }*/

.carousel__cell2:nth-child(1) { transform: rotateX(  0deg) translateZ(192px); }
.carousel__cell2:nth-child(2) { transform: rotateX( 40deg) translateZ(192px); }
.carousel__cell2:nth-child(3) { transform: rotateX( 80deg) translateZ(192px); }
.carousel__cell2:nth-child(4) { transform: rotateX(120deg) translateZ(192px); }
.carousel__cell2:nth-child(5) { transform: rotateX(160deg) translateZ(192px); }
.carousel__cell2:nth-child(6) { transform: rotateX(200deg) translateZ(192px); }
.carousel__cell2:nth-child(7) { transform: rotateX(240deg) translateZ(192px); }
.carousel__cell2:nth-child(8) { transform: rotateX(280deg) translateZ(192px); }
.carousel__cell2:nth-child(9) { transform: rotateX(320deg) translateZ(192px); }

.carousel__cell_in2{
  
  width: 92%;
  height:92%;
  margin:2% auto;
    pointer-events: none;
    opacity: 0.45
}


.carousel-options {
  text-align: center;
  position: fixed;
  bottom:20px;
  right:20px;
  z-index: 10001;
}

.opencall_container{
  overflow-x: hidden;
  overflow-y: hidden;
  /*overscroll-behavior:none;*/
}
.img_apply{
  width:70%;
  display: none;
}
.img_apply2{
  width:100%;
}
.arrowup{
  position:fixed;
  top:20px;
  left:20px;
  z-index:10000;
  width:17%;
  cursor: pointer;
}
.arrowdown{
  position:fixed;
  bottom:20px;
  right:20px;
  z-index:10000;
  width:15%;
  cursor: pointer;
}
.ovum_img{
  position:fixed;
  top:20px;
  right:20px;
  z-index:10000;
  width:22%;
  transform: rotate(45deg);
  transition: transform 5s;
}
.ovum_img2{
  position:fixed;
  top:20px;
  right:20px;
  z-index:10000;
  width:22%;
  transform: rotate(-45deg);
  transition: transform 5s;
}
.sperm_img{
  position:fixed;
  top:-5%;
  left:30%;
  z-index:20000;
  width:22%;
  -webkit-transform: translate3d(1, 0, 0);
-webkit-transform-style: preserve-3d;
-webkit-transform: translateZ(0);
-webkit-perspective: 1000;
}
.sperm_img2{
  position:fixed;
  top:-2%;
  left:34%;
  z-index:20000;
  width:22%;
  -webkit-transform: translate3d(1, 0, 0);
-webkit-transform-style: preserve-3d;
-webkit-transform: translateZ(0);
-webkit-perspective: 1000;
}
.sperm_img3{
  position:fixed;
  top:-2%;
  left:38%;
  z-index:20000;
  width:22%;
  /*transform: translate3d(1, 0, 0);*/
transform-style: preserve-3d;
transform: translateZ(100000px);
perspective: 1000;
-webkit-backface-visibility: hidden;
}
.sperm_img4{
  position:fixed;
  top:-4%;
  left:45%;
  z-index:20000;
  width:22%;
  -webkit-transform: translate3d(1, 0, 0);
-webkit-transform-style: preserve-3d;
-webkit-transform: translateZ(0);
-webkit-perspective: 1000;
}
.applybtn{
  position:fixed;
  bottom:5%;
  left:4%;
  z-index:10000;
  width:15%;
}
.applyarrowimg{
  position:fixed;
  bottom:4%;
  left:19%;
  z-index:9999;
  width:21%;
}
.carousel_title{
  position: absolute;
  width: 12%;
  height: 90%;
  display: inline-flex;
}
.carousel_content{
  position: absolute;
  left:10%;
  width: 88%;
  height: 90%;
  display: inline-block;
  overflow-y: scroll;
}
.fancy_title{
  /*font-family: 'Srisakdi', cursive;*/
  font-family: 'Akronim', cursive;
  font-size:4.5rem;
  font-weight:normal;
}
.title_small_text{
  text-align: right;
  line-height: 2.4rem;
  font-size: 2rem;
  font-style: italic;
}
.title{
  line-height:6rem;
  font-size: 5rem;
}
.each_title{
  width:80%;
  height:80%;
  z-index:12000;
  margin-left:-10%;
}
button{
  -webkit-appearance: none;
  border: none;
  background: none;
}
@media (max-width: 440px) {
  .title{
  line-height:2rem;
  font-size: 3.3rem;
}
  .title_small_text{
  line-height: 1.2rem;
  font-size: 1rem;
}
  .fancy_title{
  font-size:1.7rem;
}
    .ovum_img{
  width:27%;
  top:14px;
  right:14px;
}
 .ovum_img2{
  width:27%;
  top:14px;
  right:14px;
}
  .arrowup{
  width:22%;
  left:10px;
  top:15px;
}
.arrowdown{
  bottom:13px;
  right:13px;
  width:22%;
}

  .img_apply{
  height:160%;
}

.carousel__cell{
  font-size: 24px;
  line-height: 32px;
}

.inner_scene2{
  margin-top: -55%;
  height: 400px;
}
.img_apply2{
  width:100%;
  height:auto;
}
.container{
  padding-top:120px;
}
.inner_scene1{
  /*opacity: .8;*/
  width:100%;
}
.sperm_img{
  /*top:-1%;*/
  left:0%;
  width:42%;
}
.sperm_img2{
  /*top:-1%;*/
  left:14%;
  width:42%;
}
.sperm_img3{
  /*top:-1%;*/
  left:18%;
  width:42%;
}
.sperm_img4{
  /*top:-1%;*/
  left:25%;
  width:42%;
}
.applyarrowimg{
  position: fixed;
    bottom: 17%;
    left: -8%;
    z-index: 9999;
    width: 40%;
    transform: rotate(-90deg);
}
}
@media (max-width: 330px) {

  .scene {
  height:400px;

}
.container{
  padding-top:50px;
}
}

@media (max-width: 1200px) {
  

}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
